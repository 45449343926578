/*SEMANTIC UI*/
body {
    color: rgba(0, 0, 0, 0.75);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/**
 * SEMANTIC UI STYLE RESET 
 */

.ui.label {
    background: #eeeeee !important;
    background: rgba(0, 0, 0, 0.05) !important;
}

@media only screen and (max-width: 767px) {
    .ui.modal > .content {
        display: block;
    }
}

/* Page Context */
.pushable:not(body) {
    transform: none;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
    position: fixed;
}

.ui.grid>.orange.column, .ui.grid>.orange.row, .ui.grid>.row>.orange.column {
    background-color: #f9886c !important;
}

.ui.grid>.grey.column, .ui.grid>.grey.row, .ui.grid>.row>.grey.column {
    background-color: #eeeeee !important;
}

.ui.table {
    color: rgba(0, 0, 0, 0.75);
}

.ui.attached.menu {
    margin: 0 -2px;
}

.ui.inverted.orange.menu {
    background-color: #3887be;
}

.ui.inverted.segment,
.ui.inverted.menu,
.ui.inverted.popup {
    background-color: #2d2d2d;
}

.ui.inverted.bottom.popup:before {
    background: #222b30;
}

.ui.inverted.top.popup:before {
    background: #222b30;
}

.ui.segment, .ui.segments {
    box-shadow: none;
}

.ui.horizontal.segments {
    box-shadow: none;
}

.ui.grid > .black.column,
.ui.grid > .black.row,
.ui.grid > .row > .black.column {
    background-color: #2d2d2d !important;
}

.ui.grid > .blue.column,
.ui.grid > .blue.row,
.ui.grid > .row > .blue.column {
    background-color: #3887be !important;
}

.ui.grid > .purple.column,
.ui.grid > .purple.row,
.ui.grid > .row > .blue.column {
    background-color: #8a8acb !important;
}

.ui.form .field .prompt.label {
    background: #fff !important;
    border: 1px solid #ffdad9 !important;
    color: #ff8280 !important;
    font-weight: 400;
}

.ui.form .field.error label,
.ui.form .fields.error .field .input,
.ui.form .fields.error .field label {
    color: #ff8280;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
    width: 100%;
    border-radius: 3px !important;
    border-color: rgba(0,0,0,0.1);
}

.ui.header,
.ui.modal > .header,
.ui.form .field > label,
.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'] {
    color: rgba(0, 0, 0, 0.75);
}

.ui.inverted.form .field > label {
    color: rgba(255, 255, 255, 0.75);
}

.ui.selection.active.dropdown,
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active.dropdown .menu,
.ui.selection.dropdown:focus,
.ui.selection.dropdown:focus .menu {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-color: rgba(34, 36, 38, 0.15) !important;
}

.ui.selection.dropdown .menu {
    min-width: calc(100% + 2.5px);
}

.mb-0 {
    margin-bottom: 0 !important;
}

.text-center {
    text-align: center;
}

.text-middle {
    align-self: center;
}

.border-bottom {
    border-bottom: 1px solid rgba(255,255,255,0.1) !important;
}

.border-left {
    border-left: 1px solid rgba(255,255,255,0.1) !important;
}

.pagination.ui.secondary.menu .item {
    padding: 0.92857143em 1.14285714em;
}

.ui.dropdown .menu {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ui.secondary.segment {
    background-color: #eeeeee !important;
}

.ui.dropdown .menu > .item {
    font-size: 12px;
}

.ui.blue.button,
.ui.blue.buttons .button {
    background-color: #3887be;
}

.ui.blue.button.active,
.ui.blue.button:hover {
    background-color: #52a1d8;
}

.ui.orange.button, .ui.orange.buttons .button {
    background-color: #f9886c !important;
}

.ui.orange.button.active,
.ui.orange.button:hover {
    background-color: #ffa286 !important;
}

.ui.black.button,
.ui.black.buttons .button {
    background-color: #404040;
}

.ui.purple.button,
.ui.purple.buttons .button {
    background-color: #404040;
}

.ui.purple.button,
.ui.purple.buttons .button {
    background-color: #8a8acb;
}

.ui.purple.button:hover,
.ui.purple.buttons .button:hover {
    background-color: #a4a4e5;
}

.ui.styled.accordion,
.ui.styled.accordion .accordion {
    box-shadow: none;
    border: 1px solid rgba(34, 36, 38, 0.15);
}

.entry {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    align-items: center;
    padding-bottom: 10px;
    width: 250px;
    height: 70px;
}

.entry:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.entry-description {
    flex: 1;
    flex-direction: column;
}

.entry-actions {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-top: 50px;
}

.badge {
    background-color: #f2f2f2;
    color: #404040;
    display: inline-block;
    font-weight: 700;
    border-radius: 9999px;
    font-size: 10px;
    line-height: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 10px;
    text-align: center;
}

.badge:focus {
    outline: none;
}

.badge.select:hover {
    cursor: pointer;
}

.badge.gray {
    background-color: #eeeeee;
    color: #404040;
}

.badge.pink {
    background-color: rgba(237, 100, 152, 0.25);
    color: #ed6498;
}

.badge.red {
    background-color: rgba(229, 94, 94, 0.25);
    color: #e55e5e;
}

.badge.orange {
    background-color: rgba(249, 136, 108, 0.25);
    color: #f9886c;
}

.badge.yellow {
    background-color: rgba(241, 240, 117, 0.25);
    color: #f1f075;
}

.badge.green {
    background-color: rgba(86, 184, 129, 0.25);
    color: #56b881;
}

.badge.blue {
    background-color: rgba(59, 178, 208, 0.25);
    color: #3bb2d0;
}

.badge.purple {
    background-color: rgba(138, 138, 203, 0.25);
    color: #8a8acb;
}

.ui.dimmer {
    background: rgba(43, 46, 56, .9);
}

.ui.modal {
    box-shadow: none;
}

input:focus,
.ui.form textarea:focus {
    outline: none;
    border-color: rgba(0,0,0,.15) !important;
    color: rgba(0,0,0,.75) !important;
}

.ui.icon.input > input:focus ~ i.icon {
    opacity: 0.75 !important;
}

.ui.inverted.form textarea {
    background: rgba(0, 0, 0, 0.05) !important;
    color: rgba(255, 255, 255, 0.5)  !important;
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.ui.inverted.form textarea:focus {
    border-color: rgba(255, 255, 255, 0.15) !important;
}

.ui.inverted.form .field .ui.input input, .ui.inverted.form .fields .field .ui.input input {
    background: rgba(0, 0, 0, 0.05) !important;
    color: rgba(255, 255, 255, 0.5)  !important;
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.ui.inverted.form .field .ui.input input:focus, .ui.inverted.form .fields .field .ui.input input:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.15) !important;
}

.ui.inverted.form input[type='text']:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.25) !important;
}

.ui.inverted.form .field.error label {
    color: #fbb03b !important;
}

.ui.inverted.loading.form:before {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.ui.inverted.form .field.error input[type='text'] {
    background: rgba(255, 255, 255, 0.05) !important;
    border-color: rgba(247, 175, 25, 0.5);
    color: #fbb03b;
}

.ui.inverted.form input[type='text']::placeholder {
    color: rgba(255, 255, 255, 0.25) !important;
}

.ui.inverted.form textarea::placeholder {
    color: rgba(255, 255, 255, 0.25) !important;
}

.ui.inverted.form .field .prompt.label {
    background: #222b30 !important;
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

.ui.inverted.form .icon.input > i.icon {
    color: rgba(255, 255, 255, 0.75);
}

.ui.inverted.form input[type='text']::-webkit-input-placeholder {
    /* Edge */
    color: rgba(255, 255, 255, 0.1);
}

.ui.inverted.form input[type='text']:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.1);
}

.truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.quiet {
    color: #7f7f7f;
    color: rgba(0, 0, 0, 0.5);
}

.dark .quiet,
.black .quiet, .blue .quiet {
    color: #ffffff;
    color: rgba(255, 255, 255, 0.5);
}

.strong {
    font-weight: 700;
}

.ui.button {
    background: rgba(0, 0, 0, 0.25) none;
    -webkit-transition: background-color 0.125s, border-color 0.125s, color 0.125s;
    transition: background-color 0.125s, border-color 0.125s, color 0.125s;
    box-shadow: none !important;
    color: #ffffff;
    border-radius: 3px;
    padding: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-appearance: none;
}

.ui.button:hover {
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    box-shadow: inset rgba(0, 0, 0, 0) 0 0 0, inset rgba(0, 0, 0, 0) 0 0 0;
}

.ui.button:focus {
    box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #3bb2d0;
}

.ui.primary.button:hover,
.ui.primary.buttons .button:hover {
    background-color: #55ccea;
}

.ui.secondary.button,
.ui.secondary.buttons .button {
    background-color: rgba(0, 0, 0, 0.5);
}

.ui.secondary.button:hover,
.ui.secondary.buttons .button:hover {
    background-color: rgba(0, 0, 0, 0.75);
}

.ui.positive.button,
.ui.positive.buttons .button {
    background-color: #56b881;
}

.ui.positive.button:hover,
.ui.positive.buttons .button:hover {
    background-color: #70d29b;
}

.ui.negative.button,
.ui.negative.buttons .button {
    background-color: #ff6e7f;
}

.ui.negative.button:hover,
.ui.negative.buttons .button:hover {
    background-color: #e55e5e;
}

.ui.orange.button,
.ui.orange.buttons .button {
    background-color: #ffa950;
}

.ui.orange.button:hover,
.ui.orange.buttons .button:hover {
    background-color: #ff6e00;
}

.numberInput .ui.button {
    width: 70% !important;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(114, 199, 129, 0.75) !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    color: #ffffff;
    font-size: 10px;
    border-radius: 3px;
}

.numberInput .ui.button:hover {
    background-color: rgba(114, 199, 129, 0.85) !important;
}

.numberInput .ui.input > input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 0;
    border-right: 0;
    text-align: center !important;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
    background-color: #f2f2f2;
}

.ui.form .numberInput input[type='text']:focus {
    color: rgba(0, 0, 0, 0.95);
    border-color: rgba(0, 0, 0, 0.1) !important;
    background: #fff;
    box-shadow: none;
}

.form-label {
    display: block;
    margin: 0 0 0.28571429rem 0;
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

body ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: transparent;
    display: none;
}

body ::-webkit-scrollbar-track {
    background: transparent;
    display: none;
}

body ::-webkit-scrollbar {
    width: 0;
}



.ui.selectable.table tbody tr:hover {
    cursor: pointer;
}

.grid-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.grid-col {
    display: flex;
    flex: 1;
    flex-basis: 20%;
    -ms-flex: auto;
    width: 259px;
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.quickFacts {
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 10px;
    line-height: 1.4285em;
    color: rgba(255, 255, 255, 0.25);
    text-transform: uppercase;
    font-weight: 400;
    background-color: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 1333px) {
    .grid-col {
        flex-basis: 33.33%;
    }
}
@media (max-width: 1073px) {
    .grid-col {
        flex-basis: 33.33%;
    }
}
@media (max-width: 815px) {
    .grid-col {
        flex-basis: 50%;
    }
}
@media (max-width: 555px) {
    .grid-col {
        flex-basis: 100%;
    }
}

.quickFactsTitle {
    font-size: 2rem;
    border: none;
    padding: 0 0;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 700;
    line-height: 1.28571429em;
    text-transform: none;
    color: #ffffff;
}

.mapboxgl-canvas {
    position: static;
}

.mapboxgl-ctrl-top-right {
    transition: top 1s, right 1s;
}

.map-popup {
    display: flex;
    flex-direction: column;
    width: 257px;
    position: absolute;
    z-index: 3;
    transition: transform 1s;
    left: 0;
    top: 0;
    height: 100%;
    background: transparent;
    padding: 10px;
}

.map-popup-date {
    text-align: right;
    margin-right: 15px;
    font-size: 9px;
}

.map-popup-content-inside-balloon {
    position: relative;
    border-radius: 3px 3px 3px 20px;
    padding: 8px 15px;
    text-align: right;
}

.map-popup-content-inside-balloon::before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 30px;
}

.map-popup-content-inside-balloon::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 20px;
}

.map-popup-collapsed {
    transform: translateX(-257px);
}

.map-popup-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15);
}

.map-popup-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 3px 3px 0 0;
}

.map-popup-title {
    color: #ffffff;
    color: rgba(255, 255, 255, 0.75);
    font-weight: 700;
}

.map-popup-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #56b881;
    color: #ffffff;
    height: 15px;
    width: 15px;
    font-weight: 700;
    font-size: 8px;
    border-radius: 15px;
    animation: pulse-green 2s infinite;
}

.map-popup-content-inside {
    flex: 1;
    background: #ffffff;
    overflow-y: scroll;
    overflow-x: hidden;
}

.map-popup-content-button button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    color: rgba(0, 0, 0, 0.5);
    background: #eeeeee;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 10px;
    border-radius: 0 0 5px 5px;
}

.map-popup-content-button button:hover {
    color: rgba(0, 0, 0, 0.75);
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(86, 184, 129, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(86, 184, 129, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(86, 184, 129, 0);
    }
}


.tab {
    padding: 0 !important;
    border: 0 !important;
}

/* TOAST */
.toast-container {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: normal;
    top: 10px;
    right: 10px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
    box-sizing: border-box;
    position: fixed;
    z-index: 999999;
}

.toast {
    display: flex;
    flex-direction: row;
    transition: 0.5s ease;
    position: relative;
    margin: 0 0 15px;
    min-height: 70px;
    max-height: 130px;
    border-radius: 3px;
    width: 300px;
    color: rgba(255, 255, 255, 0.75);
    background-color: #404040;
    -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.toast button {
    background: transparent;
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: #ffffff;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 700;
}

.toast-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
    font-weight: 700;
    background-color: #404040;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: 0.5s ease;
}

.toast-button-container:focus {
    box-shadow: none;
    outline: none;
    border: 0;
}

.toast-button-container:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
}

.toast-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.toast-icon-container img {
    width: 50px;
}

.toast-text-container {
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    width: 200px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.toast-title {
    font-weight: 700;
    color: #db2828;
}

.toast-message {
    overflow: hidden;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.ui.green.label, .ui.green.labels .label {
    background-color: #56b881 !important;
    border-color: #56b881 !important;
}

.ui.yellow.label, .ui.yellow.labels .label {
    background-color: #f1f075 !important;
    border-color: #f1f075 !important;
    color: #2d2d2d !important;
}

.pool-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 9px;
    background-color: #eee;
    border-radius: 3px;
    cursor: pointer;
    height: 50px;
    margin: 5px;
    font-weight: 700;
    transition: background-color .3s;
}

.pool-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.pool-button:focus {
    outline: 0;
    box-shadow: none;
}

.loadMore {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadMore button {
    font-size: 12px;
    cursor: pointer;
    outline: 0;
    border: none;
    vertical-align: baseline;
    background: none;
    color: #3887be;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    box-shadow: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.loadMore button:hover {
    color: #52a1d8;
}

.fade-in {
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

.counter-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
}

.counter-input {
    text-align: center;
    height: 50px;
    flex: 1;
    padding: 5px 5px;
    font-weight: 700;
}

.counter-label {
    position: relative;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
    font-size: 0.92857143em;
    background: #eeeeee;
    border-radius: 15px;
    padding: 5px 10px;
    width: 100%;
}

.counter-label::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-radius: 3px;
    border-color: #eeeeee transparent transparent transparent;
}

.counter-label-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.counter-badge {
    position: absolute;
    display: flex;
    top: -10px;
    right: -10px;
    background-color: #e55e5e;
    width: 25px;
    height: 25px;
    z-index: 2;
    border-radius: 50px;
    animation: pulse-red 2s infinite;
    color: rgba(255, 255, 255, 0.75);
    font-size: 9px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

small,
.small {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0;
}

.x-small {
    font-size: 10px;
    line-height: 1.5;
}

.red {
    color: #e55e5e;
}

.orange {
    color: #f9886c;
}

.green {
    color: #56b881;
}

.ui.inverted.attached.segment {
    border: 0;
}

.balloon-container i.icon {
    margin: 0;
}

.balloon-button {
    position: fixed;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    bottom: 10px;
    right: 10px;
    background-color: #2d2d2d;
    color: rgba(255, 255, 255, 0.75);
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: 700;
    font-size: 10px;
}

.balloon-button.active,
.balloon-button:hover {
    background-color: #2d2d2d;
    color: #ffffff;
}

.balloon-button:focus {
    outline: 0;
    box-shadow: none;
}

.balloon {
    transition: visibility 0s, opacity 0.5s ease;
    position: fixed;
    z-index: 100;
    right: 20px;
    bottom: 60px;
    width: 250px;
    color: rgba(255, 255, 255, 0.5);
    background-color: #2d2d2d;
    font-size: 10px;
    border-radius: .28571429rem;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.balloon:before {
    bottom: -.30714286em;
    right: 1em;
    top: auto;
    left: auto;
    margin-left: 0;
    background: #2d2d2d;
    position: absolute;
    content: '';
    width: .71428571em;
    height: .71428571em;
    transform: rotate(45deg);
    z-index: 2;
    box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.2);
}

@keyframes slideUp {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-500px);
    }
}

.balloon-buttons {
    display: flex;
}

.balloon button {
    display: flex;
    flex: 1;
    justify-content: center;
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.5) !important;
    padding: 15px !important;
    border-radius: 0 !important;
    border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
    transition: all 0.5s ease;
    margin: 0 !important;
}

.balloon button:focus,
.balloon button:hover {
    outline: 0;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.15);
    color: rgba(255, 255, 255, 0.75) !important;
}

.balloon button:first-child {
    -webkit-border-bottom-left-radius: 5px !important;
    -moz-border-radius-bottomleft: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.balloon button:last-child {
    border: 0 !important;
    -webkit-border-bottom-right-radius: 5px !important;
    -moz-border-radius-bottomright: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.balloon-title {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    padding: 15px 10px;
    font-weight: 700;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.balloon-content {
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.error-balloon {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #e55e5e;
}

.label {
    display: flex;
    margin: 0 0 0.28571429rem 0;
    font-size: 0.92857143em;
    font-weight: 700;
    align-items: center;
}

.error-label {
    font-weight: 400;
    font-size: 0.92857143em;
    color: #e55e5e;
}

.label-asterisk {
    margin: -0.2em 0 0 0.2em;
    color: #e55e5e;
}

.error-badge {
    display: inline-block;
    background-color: #e55e5e;
    width: 7px;
    height: 7px;
    z-index: 2;
    border-radius: 50px;
    animation: pulse-red 2s infinite;
    margin-right: 5px;
}

.warning-badge {
    display: inline-block;
    background-color: #f1f075;
    width: 7px;
    height: 7px;
    z-index: 2;
    border-radius: 50px;
    animation: pulse-warning 2s infinite;
    margin-right: 5px;
}

@keyframes pulse-warning {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(241, 240, 117, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(241, 240, 117, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(241, 240, 117, 0);
    }
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(229, 94, 94, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(229, 94, 94, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(229, 94, 94, 0);
    }
}

.ui.inverted.button {
    transition: 0.3s;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.25) inset !important;
}

.ui.inverted.button:hover,
.ui.inverted.button.active {
    background-color: transparent !important;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.75) inset !important;
    color: rgba(255, 255, 255, 0.75) !important;
}

.counter-button {
    display: inline-flex;
    height: 50px;
    color: #ffffff;
    width: 70%;
    font-size: 10px;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-decoration: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    background-image: none;
    background-color: #404040;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border: 0;
}

.counter-button:hover {
    background: #a4a4e5;
}

.counter-button:focus {
    outline: 0;
    outline-offset: 0;
}

.counter-button i.icon {
    margin: 0;
}

.counter-button.minus {
    margin-right: 5px;
    background-color: #8a8acb;
}

.counter-button.plus {
    margin-left: 5px;
    background-color: #3bb2d0;
}

.counter-button.plus:hover {
    margin-left: 5px;
    background-color: #55ccea;
}

.note-container {
    position: fixed;
    width: 100%;
    background-color: #f1f075;
    z-index: 101;
    bottom: -510px;
    animation: show 2.5s ease-in forwards;
}

.note {
    font-size: 0.92857143rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 70px;
}

.note p {
    margin-bottom: 0;
}

@keyframes show {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-510px);
    }
}

.flex {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-flow: row wrap;
}

.fill-black .border-bottom {
    border-bottom: 1px solid rgba(255,255,255,0.1) !important;
}

.border-bottom {
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.border-top {
    border-top: 1px solid rgba(0,0,0,0.1)
}

.fill-black .border-top {
    border-top: 1px solid rgba(255,255,255,0.1);
}

.map-container {
    font-size: 12px;
}

.fill-white {
    background: #ffffff;
}

.fill-black {
    background: #2d2d2d;
    color: #ffffff;
    color: rgba(255, 255, 255, 0.5);
}

.fill-grey {
    background: #eeeeee;
}

.p-0 {
    padding: 0 !important;
}

.p-10 {
    padding: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.round {
    border-radius: 3px;
}

.round-top {
    border-radius: 3px 3px 0 0;
}

.round-right {
    border-radius: 0 3px 3px 0;
}

.round-bottom {
    border-radius: 0 0 3px 3px;
}

.round-left {
    border-radius: 3px 0 0 3px;
}

.round-topleft {
    border-top-left-radius: 3px;
}

.round-bottomleft {
    border-bottom-left-radius: 3px;
}

.round-topright {
    border-top-right-radius: 3px;
}

.round-bottomright {
    border-bottom-right-radius: 3px;
}

.h-500 {
    height: 500px;
}

.mh-500 {
    height: 500px;
}

.w-1\/3 {
    width: 33.333333%
}

.w-2\/3 {
    width: 66.666667%
}

.map-popup-small-button {
    text-align: center;
    display: inline-block;
    padding: 1px 10px;
    position: relative;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 20px;
    font-size: 9px;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: #ffffff;
    font-weight: 700;
    background: #56b881;
    margin-top: 5px;
}

.map-popup-small-button:hover {
    background-color: #70d29b;
}

.map-column:last-child {
    border-left: 0;
}

.map-list {
    overflow-y: scroll;
    overflow-x: hidden;
}

.map-sidebar {
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.map-sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    padding: 5px 10px;
    font-size: 11px;
}

.sidebar-item:hover, .sidebar-item.list-active-item {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
}

.map-sidebar .map-buttons {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.map-sidebar .map-buttons button {
    flex: 1;
    padding: 10px;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.5);
    font-size: 11px;
    transition: 0.3s;
}

.map-sidebar .map-buttons button:hover {
    color: rgba(255, 255, 255, 0.75);
}

.map-sidebar .map-buttons button:focus, .map-sidebar .map-buttons button:active {
    color: rgba(255, 255, 255, 0.75);
    outline: 0;
    box-shadow: none;
}

.map-sidebar .map-buttons button:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.map-title {
    color: #ffffff;
    font-weight: 700;
}

a.map-button {
    width: 50%;
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: all .3s;
    padding: 5px 10px;
    text-align: center;
    background: #2d2d2d;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-weight: 700;
    border-radius: 0 0 0 5px;
}

a.map-button:last-child {
    border-right: 0;
    border-radius: 0 0 5px 0;
}

a.map-button:hover {
    color: rgba(255, 255, 255, 0.75);
}

.map-marker {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 25px;
    height: 25px;
    font-size: 9px;
    background-color: #f9886c;
    color: #ffffff;
    font-weight: 700;
    padding: 10px;
    border-radius: 50px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    transition: background .2s ease-in-out;
}

.map-marker:hover {
    background: #e55e5e;
    animation: pulse 2s linear infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(229, 94, 94, 0.75);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(229, 94, 94, 0.5);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(229, 94, 94, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(229, 94, 94, 0.4);
        box-shadow: 0 0 0 0 rgba(229, 94, 94, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(229, 94, 94, 0);
        box-shadow: 0 0 0 10px rgba(229, 94, 94, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(229, 94, 94, 0);
        box-shadow: 0 0 0 0 rgba(229, 94, 94, 0);
    }
}


.italic {
    font-style: italic;
}

.text-right {
    text-align: right;
}

@media (max-width: 555px) {
    .w-1\/3 {
        width: 100%
    }

    .w-2\/3 {
        width: 100%
    }
}

.mapboxgl-popup-close-button {
    display: none;
}

.mapboxgl-popup-content {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
    font-size: 10px !important;
    padding: 0 !important;
    width: 180px;
    border-radius: 3px !important;
    background: transparent !important;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15) !important;
}

.mapboxgl-popup-content h3 {
    color: #ffffff;
    color: rgba(255, 255, 255, 0.75);
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    font-size: 10px;
    margin: 0;
    padding: 10px;
    background: #2d2d2d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mapboxgl-popup-content h4 {
    font-size: 10px;
    padding: 20px 10px;
    margin: 0;
    background: #2d2d2d;
    color: #7f7f7f;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    text-align: center;
}

.mapboxgl-container .leaflet-marker-icon {
    cursor: pointer;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-bottom-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-right-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-left-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-top-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-top-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-bottom-left a.map-button {
    border-radius: 0 !important;
}

.mapboxgl-popup-anchor-bottom-left a.map-button:last-child {
    border-bottom-right-radius: 3px !important;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-top-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-bottom-right a.map-button:last-child {
    border-radius: 0;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-top-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-bottom-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border: 5px solid transparent;
    border-bottom-color: #2d2d2d !important;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-content h3  {
    border-top-left-radius: 0 !important;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-content h3  {
    border-top-right-radius: 0 !important;
}

/*SWITCH*/
.switch-container {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: -webkit-inline-flex;
    display: inline-flex;
}

.switch-container > input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

.switch {
    cursor: pointer;
    top: 3px;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    position: relative;
    width: 30px;
    height: 18px;
    border-radius: 9999px;
    border-width: 2px;
    border-style: solid;
    color: #999999;
    transition: color .125s, background-color .125s, border-color .125s;
}

.switch::after {
    display: block;
    width: calc(50% - 3px);
    content: '';
    background-color: #999999;
    position: absolute;
    border-radius: 50%;
    left: 2px;
    top: 2px;
    bottom: 2px;
    transition: left .125s, background-color .125s;
}

input:checked + .switch::after {
    left: calc(50% + 1px);
    background-color: #fff;
}

input:checked + .switch {
    border-color: transparent;
    background-color: #3bb2d0;
}

input:checked + .switch {
    border-color: transparent;
    background-color: #3bb2d0;
}

input:checked:disabled + .switch, input:disabled + .checkbox, input:disabled + .switch {
    pointer-events: none;
    color: rgba(0, 0, 0, .25);
    background-color: rgba(127, 127, 127, .25);
    border-color: transparent;
}

input:disabled + .switch::after {
    background-color: rgba(0, 0, 0, .25);
}

.messenger {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.messenger-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 64px;
    color: #ffffff;
    color: rgba(255,255,255,0.75);
}

.messages-date {
    text-align: right;
    margin-bottom: 5px;
}

.messages-delete {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0 10px;
    color: rgba(255,255,255,0.5);
    cursor: pointer;
}

.messages-delete:focus {
    outline: 0;
    box-shadow: none;
}

.messages-delete:hover {
    color: rgba(255,255,255,0.75);
}

.messages-inner {
    position: relative;
    border-radius: 10px 10px 20px 10px;
    padding: 8px 15px;
    background: #404040;
    color: #ffffff;
    color: rgba(255,255,255,0.5);
    margin-bottom: 20px;
}

.messages-inner::before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -7px;
    height: 20px;
    width: 20px;
    background: #404040;
    border-bottom-left-radius: 30px;
}

.messages-inner::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: #2d2d2d;
    border-bottom-left-radius: 30px;
}

.messages {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll;
}

.flex-middle {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

i.inverted.bordered.green.icon, i.inverted.circular.green.icon {
    background-color: #56b881 !important;
}

i.inverted.bordered.green.icon:hover, i.inverted.circular.green.icon:hover {
    background-color: #70d29b !important;
}

.blinking {
    animation: blinking 0.8s infinite;
}

.code {
    font-family: Menlo, Bitstream Vera Sans Mono, Monaco, Consolas, monospace;
    white-space: pre-wrap;
}

.cube {
    font-size: 11px;
    line-height: 1.5;
    background: #eeeeee;
    background: rgba(255,255,255,0.5);
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
}

.cube-header {
    font-family: 'Menlo', 'Bitstream Vera Sans Mono', 'Monaco', 'Consolas', monospace;
    font-size: 11px;
    line-height: 1.5;
    color: #ffffff;
    color: rgba(255, 255, 255, 0.5);
    background: #2d2d2d;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    border-radius: 3px 5px 0 0;
}

.pre {
    border-radius: 3px;
    border: 1px solid rgba(0,0,0,0.1);
    min-height: 400px;
}

.pre-header {
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.pre-header::before {
    content: '';
    display: inline-block;
    justify-content: center;
    background: #56b881;
    height: 10px;
    width: 10px;
    border-radius: 15px;
    margin-right: 5px;
    animation: pulse-green 2s infinite;
}

.pre-loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0;
}

.placeholder {
    display: block;
    height: 5px;
    width: 35%;
    background: #f2f2f2;
    background: rgba(0,0,0,0.05);
    margin-bottom: 10px;
}

.divider {
    height: 1px;
    background: #eeeeee;
    background: rgba(0,0,0,0.1);
    margin: 10px 0;
}

.full-width {
    width: 100%;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@keyframes blinking {
    0% {
        color: rgba(255, 255, 255, 0.5);
    }
    49% {
        color: transparent;
    }
    50% {
        color: transparent;
    }
    99% {
        color: transparent;
    }
    100% {
        color: rgba(255, 255, 255, 0.5);
    }
}

.message-loading:after,
.message-loading:before {
    content:'';
    display:block;
}


.message-loading::after {
    content: '';
    display: block;
    margin: auto;
    height: 18px;
    width: 18px;
    -webkit-animation: spin .8s infinite cubic-bezier(.45,.05,.55,.95);
    animation: spin .8s infinite cubic-bezier(.45,.05,.55,.95);
}

.message-loading::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' width='18' height='18' viewBox='0 0 18 18'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bopacity:0.1;%7D.b%7Bfill:%23fff;%7D.c%7Bfill:%23f7f7f7;%7D%3C/style%3E%3C/defs%3E%3Cg class='a'%3E%3Cpath class='b' d='M13.2426,13.2426A6,6,0,0,1,4.7574,4.7574L2.636,2.636a9,9,0,1,0,12.7279,0L13.2426,4.7574A6,6,0,0,1,13.2426,13.2426Z'/%3E%3C/g%3E%3Cpath class='c' d='M2.636,2.636,4.7574,4.7574a6,6,0,0,1,8.4853,0L15.364,2.636A9,9,0,0,0,2.636,2.636Z'/%3E%3C/svg%3E");
}

i.purple.icon {
    color: #8a8acb!important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.dark-segment {
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 3px !important;
    color: #ffffff !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

.dark-box-header {
    background-color: rgba(0,0,0,0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 0;
    padding: 10px;
    font-weight: 700;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.dark-box-footer {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 0;
    padding: 10px;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.dark-box-container {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 30px;
}

.scroll-y {
    overflow-y: auto !important;
    height: 1px;
}
